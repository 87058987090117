<template>
  <div style="background-color: #FAFAFA">
    <div>
      <span class="detail-title"><button class="title-button" @click="backPre">{{activeName}} > </button> {{bookData.name}}</span>
    </div>
    <div class="detail-content">
      <el-row><span class="content-title title">{{bookData.name}}</span></el-row>
      <el-row type="flex" justify="space-between" class="content-details-top">
        <el-col>
          <div class="grid-content bg-purple" justify="start">
            <img style="height: 300px;" :src="bookData.cover" fit="cover" />
          </div>
        </el-col>
        <el-col>
          <div>
            <div class="content-label">作者：<span class="content-value">{{bookData.authorName}}</span></div><br />
            <div class="content-label">出版社：<span class="content-value">{{bookData.publisherName}}</span></div><br />
            <!-- <div class="content-label">出品方：<span class="content-value">。。。。</span></div><br />
            <div class="content-label">原作名：<span class="content-value">....</span></div><br />
            <div class="content-label">译者：<span class="content-value">....</span></div><br /> -->
            <div class="content-label">货源地：<span class="content-value">{{bookData.goodsSource}}</span></div><br />
            <div class="content-label">语种：<span class="content-value">{{bookData.language}}</span></div><br />
            <div class="content-label">出版年：<span class="content-value">{{bookData.publishTime}}</span></div><br />
            <div class="content-label">分类：<span class="content-value">{{bookData.categoryName}}</span></div><br />
          </div>
        </el-col>
        <div style="width: 0px;height: 262px;border: 2px solid #707070;opacity: 0.5; padding: 0;"></div>
        <el-col>
        </el-col>
        <el-col style="padding-top: 50px;">
          <div class="grid-content bg-purple">
            <!-- <div class="content-label">{{activeName}}价：<span class="content-value">{{bookData.retailPrice}}</span></div><br /> -->
            <div class="content-label">价格：<span class="content-value">面议</span></div><br />
			<div class="content-label">发布人：<span class="content-value">{{bookData.createdName}}</span></div><br />
            <div class="content-label">发布日期：<span class="content-value">{{bookData.createdAt}}</span></div><br />
            <div class="content-label">联系方式：<span class="content-value">{{bookData.phone | hideStr}}</span></div><br />
          </div>
          <button @click="toLogin" style="height: 30px;width: 70px;background-color: #F5B300;border-radius: 24px;">联系TA</button>
        </el-col>
      </el-row>
      <div class="content-detials-button-title title">内容简介</div>
      <el-row :gutter="5" class="content-details-button">
        <el-col :span="24" class="content-details-button-text">
          <div v-html="changeWord" class="content-details-summary"></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    filters: {
      hideStr(val) {
        console.log("val",val)
        val = val ? val.slice(0,val.length - 4) + "****" : val
        return val
      }
    },
    data() {
      return {
        bookData: {},
        activeName: "",
      }
    },
    created() {
      this.getDetail();
      this.activeVal();
    },
    methods: {
       toLogin() {
        window.location.href = "https://console.mekoom.com?ReturnUrl=https://www.mekoom.com/"
      },
      chatIt() {
        console.log("chatIt")
      },
      async getDetail() {
        const data = await this.$Axios.getDetail(this.$route.query.id);
        this.bookData = data.data;
      },
      // 返回table页
      backPre() {
        this.$router.push({
          name: 'Home'
        });
      },
      activeVal() {
        const data = this.$route.query.activeName
        if(data == 'first') {
          this.activeName = "供应"
        }
        if(data == 'second') {
          this.activeName = "求购"
        }
      }
    },
    computed: {
      changeWord() {
        // const re = '&lt;bt&gt';
        if(this.bookData.summary == null || this.bookData.summary == ""){
          return ;
        }
        const i = this.bookData.summary.replace(/&lt;br&gt;/g, '<br/>');
        return i;
      }
    }
  }
</script>

<style>
  .detail-title {
    text-align: left;
    font-size: 120%;
    font-weight: bold;
    color: #13BBB9;
  }

  .title {
    width: 160px;
    height: 28px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 28px;
    color: #1A3664;
    opacity: 1;
  }

  .title-button {
    height: 24px;
    font-size: 20px;
    font-weight: bold;
    border: 0;
    color: #413434;
    opacity: 1;
    background-color: #FAFAFA;
  }

  .detail-content {
    background-color: #FFFFFF;
    padding-top: 30px;
  }

  .content-details-top {
    padding-left: 30px;
  }

  .el-row {
    margin-bottom: 20px;
    margin-left: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    border-radius: 4px;
    margin-left: 30px;
  }

  .bg-purple-dark {}

  .bg-purple {}

  .bg-purple-light {}

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }

  .row-bg {
    padding: 10px 0;

  }

  .content-title {
    width: 160px;
    height: 28px;
  }

  .content-label {
    width: 400px;
    padding-bottom: 10px;
    color: #9699A0;
  }

  ,
  .content-value {
    width: 400px;
    padding-bottom: 10px;
    color: #27426D;
  }

  .content-details-button {
    padding-left: 30px;
    width: 87.5%;
    color: #000000;
  }

  .content-detials-button-title {
    height: 28px;
    width: 80px;
    margin-left: 30px;
  }

  .content-details-button-text {
    height: 450px;
    margin-top: 20px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 28px;
    color: #1A3664;
    opacity: 1;
    position: relative;
    overflow: hidden;
  }

  .content-details-summary {
    position: absolute;
    left: 0;
    top: 0;
    right: -17px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }
</style>
